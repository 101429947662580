// Configuración de variables de entorno de avaluo digital
let config = {};

// Entorno producción
if (process.env.VUE_APP_ENV === "production") {
    config.avaluo_base_url  = 'https://hz5gajtqmc.execute-api.us-east-1.amazonaws.com/prod/api/v1'
    config.token_base_url   = 'https://api-ad.bienparabien.com/api/v1/ad/token'
    config.get_documents_url= 'https://api-ad.bienparabien.com/api/v1/ad/document'
    config.descarga_doc_url = 'https://api-ad.documents.bienparabien.com/api/v1/ad/document'
    config.image_render_url = 'https://img-api.codster.io/api/v1/image?url='
    config.avaluo_ai360_url = 'https://avaluo.ai360.mx'
}

// Entornos dev/test
else {
    config.avaluo_base_url  = 'https://3wln0uz0od.execute-api.us-east-1.amazonaws.com/test/api/v1'
    config.token_base_url   = 'https://zei26kyqo6.execute-api.us-east-1.amazonaws.com/test/api/v1/ad/token'
    config.get_documents_url= 'https://zei26kyqo6.execute-api.us-east-1.amazonaws.com/test/api/v1/ad/document'
    config.descarga_doc_url = 'https://ceffg69v3e.execute-api.us-east-1.amazonaws.com/test/api/v1/ad/document'
    config.image_render_url = 'https://api-img.dev.codster.io/api/v1/image?url='
    config.avaluo_ai360_url = 'https://test.avaluo.ai360.mx'
}

export default config