import axios from '@/plugins/axios'
import config from '@/apps/avaluodigital/config'

class Avaluo {

    setGlobalToken(tokenAD){
        this.tokenAD = tokenAD;
    }

    getHeaderXAuth(){
        return { headers:{'X-Authorization': `Bearer ${this.tokenAD}`} }
    }

    creacion_solicitud(payload){
        return axios.post(config.avaluo_base_url + "/appraisal", payload)
    }

    start_solicitud(id){

        const url = `${config.avaluo_base_url}/appraisal/start`;
        const headers = this.getHeaderXAuth();
        return new Promise(function(resolve, rej) {
                resolve(axios.post(url,{id:id}, headers));
            })
            .then(function(result) {
                return result;
            })
            .catch(function(err) {
                console.error('Error: ',err.response.data)
                return null
            })
    }

    consulta_solicitudes(page,limit) {
        let url = config.avaluo_base_url + "/appraisal"
        if (page && limit && parseInt(page) > 0 && limit && parseInt(limit) > 0){
            url = `${url}?page=${page}&page_size=${limit}`
        }else if (page && parseInt(page) > 0 ){
            url = `${url}?page=${page}`
        }
        const solicitudes = axios.get(url, this.getHeaderXAuth())
        return solicitudes
    }

    consulta_valuadores(){
        return axios.get(config.avaluo_base_url + "/appraiser", this.getHeaderXAuth())
    }

    consulta_zonas(){
        return axios.get(config.avaluo_base_url + "/zone", this.getHeaderXAuth())
    }

    consulta_solicitud(id) {
        const url = `${config.avaluo_base_url}/appraisal/${id}`;
        const headers = this.getHeaderXAuth();
        return new Promise(function(resolve, rej) {
            resolve(axios.get(url, headers));
        })
        .then(function(result) {
            return result;
        })
        .catch(function(err) {
            console.error('Error: ', err.response.data)
            return null
        })
    }

    update_solicitud(id, payload) {
        return axios.patch(config.avaluo_base_url + "/appraisal/" + id, payload, this.getHeaderXAuth())
    }

    rechaza_solicitud(id, motivo) {
        let payload = {
            reject_reason: motivo
        }
        return axios.post(config.avaluo_base_url + "/appraisal/" + id + '/reject', payload, this.getHeaderXAuth())
    }

    obtener_token(payload) {
        return axios.post(config.token_base_url,payload)
    }

    update_solicitante(id, payload) {
        return axios.patch(config.avaluo_base_url + "/applicant/" + id, payload, this.getHeaderXAuth())
    }

    update_inmueble(id, payload) {
        return axios.patch(config.avaluo_base_url + "/property/" + id, payload, this.getHeaderXAuth())
    }

    update_terreno(id, payload) {
        return axios.patch(config.avaluo_base_url + "/terrain/" + id, payload, this.getHeaderXAuth())
    }

    update_urban_env(id, payload) {
        return axios.patch(config.avaluo_base_url + "/environment/" + id, payload, this.getHeaderXAuth())
    }
}

export default new Avaluo();