<template>
<div class="container-fluid">

	<div>
		<div class="row my-4">
			<h1 class="col-sm-12 text-right">Edición avaluo</h1>
		</div>
	</div>

    <br />
    <div>
    </div>
    <div class="row">

        <div class="col-md-9">
            <div class="solicitudes">
                <div class="solicitud">
                    <div class="bpb-card">
                        <div class="card-header">

                            <div class="row">
                                <div class="col-11">
                                    <div class="card-title">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                        </svg>
                                        Generales
                                    </div>
                                </div>
                                <div class="col-1 d-flex justify-content-end">
                                    <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>


                        <div class="collapse" id="collapseOne">
                            <form @submit="update_generales">
                            <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="no_solicitud">No. Solicitud</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="no_solicitud"
                                                disabled
                                                v-model="solicitud.external_id">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="select-estatus">Estatus</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="select-estatus"
                                                disabled
                                                v-model="solicitud.status.name">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="prop_avaluo">Propósito del avalúo</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="prop_avaluo"
                                                v-model="solicitud.purpose">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="obj_avaluo">Objeto del avalúo</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                v-model="solicitud.object_appraisal">
                                            </div>

                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="entidad_ot_cred">Entidad otorgante del crédito</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="entidad_ot_cred"
                                                disabled
                                                v-model="solicitud.entity_credit">
                                            </div>

                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="ub_geo_inmueble">Dirección</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="ub_geo_inmueble"
                                                disabled
                                                v-model="solicitud.property.address">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="reg_prop">Régimen de propiedad</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="reg_prop"
                                                v-model="solicitud.property.regime">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="uso_suelo_inm">Uso de suelo del inmueble</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="uso_suelo_inm"
                                                v-model="solicitud.property.land_user">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="num_cuenta_agua">Número de cuenta de agua</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="num_cuenta_agua"
                                                v-model="solicitud.property.water_account_number">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="num_cuen_pred">Número de cuenta predial</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="num_cuen_pred"
                                                v-model="solicitud.property.property_account_number">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="tipo_inm">Tipo de inmueble</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="tipo_inm"
                                                disabled
                                                v-model="solicitud.property.type_property.description">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="nom_des">Nombre del desarrollador</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="nom_des"
                                                v-model="solicitud.property.owner_name">
                                            </div>
                                        </div>
                                    </div>

                                    <h3>
                                        Coordenadas
                                    </h3>


                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="latitud">Latitud</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="latitud"
                                                disabled
                                                v-model="solicitud.property.latitude">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group inline">
                                                <label for="longitud">Longitud</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="longitud"
                                                disabled
                                                v-model="solicitud.property.longitude">
                                            </div>
                                        </div>
                                    </div>

                            </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button v-if="showGuardarButton" type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Solicitante
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="collapse" id="collapseTwo">
                        <form @submit="update_solicitante"> 
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="nombre">Nombre</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="nombre"
                                            disabled
                                            v-model="solicitud.applicant.full_name">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <input class="checkbox-style" type="checkbox" v-model="usar_direccion" id="checkbox_direccion" v-on:change="change_dir_avaluo">
                                                <label for="checkbox_direccion" style="padding: 1px 5px" class="label-checkbox-style">Marcar para usar la misma dirección del inmueble a valuar</label>
                                                <input  type="text"
                                                class="form-control input-square"
                                                id="direccion"
                                                :disabled="usar_direccion == true"
                                                v-model="solicitud.applicant.address">
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="rfc">RFC</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="rfc"
                                            v-model="solicitud.applicant.rfc">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="curp">CURP</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="curp"
                                            v-model="solicitud.applicant.curp">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button v-if="showGuardarButton" type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <!--
                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Declaraciones
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapseThree">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group inline">
                                        <label for="comment">Declaraciones</label>
                                        <textarea class="form-control" id="comment" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group inline">
                                        <label for="comment">Advertencias</label>
                                        <textarea class="form-control" id="comment" rows="5"></textarea>
                                    </div>
                                </div>
                            </div>

                        </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button v-if="showGuardarButton" type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div> 
                -->

                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Entorno
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapseFour">
                        <form @submit="update_urban_env">
                        <div class="card-body">
                            <div>
                                <h3>Entorno urbano</h3>
                                <div class="separator-dashed"></div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="clas_zona">Clasificación de la zona</label>
                                            <select v-model="solicitud.urban_environment.zone_classification" ref="clas_zona" class="form-control">
                                                <option value="Habitacional de lujo">Habitacional de lujo</option>
                                                <option value="Habitacional de primer orden">Habitacional de primer orden</option>
                                                <option value="Habitacional de segundo orden">Habitacional de segundo orden</option>
                                                <option value="Habitacional de tercer orden">Habitacional de tercer orden</option>
                                                <option value="Habitacional de interés social">Habitacional de interés social</option>
                                                <option value="Habitacional popular">Habitacional popular</option>
                                                <option value="Habitacional campestre">Habitacional campestre</option>
                                                <option value="Comercial de lujo">Comercial de lujo</option>
                                                <option value="Comercial de primer orden">Comercial de primer orden</option>
                                                <option value="Comercial de segundo orden">Comercial de segundo orden</option>
                                                <option value="Comercial de tercer orden">Comercial de tercer orden</option>
                                                <option value="Industrial">Industrial</option>
                                                <option value="Mixta habitacional y comercial">Mixta habitacional y comercial</option>
                                                <option value="Mixta industrial y comercial">Mixta industrial y comercial</option>
                                                <option value="Mixta habitacional e industria vecina">Mixta habitacional e industria vecina</option>
                                                <option value="Mixta Habitacional y servicios">Mixta Habitacional y servicios</option>
                                                <option value="Mixta industrial y servicios">Mixta industrial y servicios</option>                                      
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="niv_perm">Niveles permitidos</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="niv_perm"
                                            :disabled="empty_urban_env.includes('niv_perm')"
                                            v-model="solicitud.urban_environment.allowable_levels">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="uso_const">Uso de las construcciones</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="uso_const"
                                            :disabled="empty_urban_env.includes('uso_const')"
                                            v-model="solicitud.urban_environment.use_constructions">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="den_pob">Densidad de población</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="den_pob"
                                            :disabled="empty_urban_env.includes('den_pob')"
                                            v-model="solicitud.urban_environment.population_density">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="rep_den">Reporte de densidad según</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="rep_den"
                                            :disabled="empty_urban_env.includes('rep_den')"
                                            v-model="solicitud.urban_environment.density_report">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="niv_soc">Nivel socioeconómico de la zona</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="niv_soc"
                                            :disabled="empty_urban_env.includes('niv_soc')"
                                            v-model="solicitud.urban_environment.socieconomic_level">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group inline">
                                            <label for="vias_acceso">Vías de acceso</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="vias_acceso"
                                            :disabled="empty_urban_env.includes('vias_acceso')"
                                            v-model="solicitud.urban_environment.access_roads">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </br>
                            </br>

                            <div>
                                <h3>Equipamiento urbano</h3>
                                <div class="separator-dashed"></div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="infr_disp">Infraestructura disponible en la zona</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="infr_disp"
                                            :disabled="empty_urban_env.includes('infr_disp')"
                                            v-model="solicitud.urban_environment.infrastructure_level">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="vialidades">Vialidades</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="vialidades"
                                            :disabled="empty_urban_env.includes('vialidades')"
                                            v-model="solicitud.urban_environment.roads">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="guarniciones">Guarniciones</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="guarniciones"
                                            :disabled="empty_urban_env.includes('guarniciones')"
                                            v-model="solicitud.urban_environment.side_dishes">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="al_publico">Alumbrado público</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="al_publico"
                                            :disabled="empty_urban_env.includes('al_publico')"
                                            v-model="solicitud.urban_environment.public_lighting">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="red_dist_agua">Red de distribución de agua potable</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="red_dist_agua"
                                            :disabled="empty_urban_env.includes('red_dist_agua')"
                                            v-model="solicitud.urban_environment.driking_water">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="red_rec_ag_res">Red de recolección de aguas residuales</label>
                                            <input   type="text"
                                            class="form-control input-square"
                                            id="red_rec_ag_res"
                                            :disabled="empty_urban_env.includes('red_rec_ag_res')"
                                            v-model="solicitud.urban_environment.network_wastewater">
                                        </div>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="banquetas">Banquetas</label>
                                            <input   type="text"
                                            class="form-control input-square"
                                            id="banquetas"
                                            :disabled="empty_urban_env.includes('banquetas')"
                                            v-model="solicitud.urban_environment.benches">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="gas">Gas natural</label>
                                            <input   type="text"
                                            class="form-control input-square"
                                            id="gas"
                                            :disabled="empty_urban_env.includes('gas')"
                                            v-model="solicitud.urban_environment.naturalgas">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="vigilancia">Vigilancia</label>
                                            <select v-model="solicitud.urban_environment.surveillance" ref="vigilancia" class="form-control">
                                                <option value="Municipal">Municipal</option>
                                                <option value="Autónoma privada">Autónoma privada</option>
                                                <option value="No tiene">No tiene</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="acom_inm_sum_el">Acometida al inmueble suministro eléctrico</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="acom_inm_sum_el"
                                            :disabled="empty_urban_env.includes('acom_inm_sum_el')"
                                            v-model="solicitud.urban_environment.connection_electrical">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group inline">
                                            <label for="red_drenaje">Red de drenaje de aguas pluviales en la calle</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="red_drenaje"
                                            :disabled="empty_urban_env.includes('red_drenaje')"
                                            v-model="solicitud.urban_environment.rainwater_drainage_network">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            </br>
                            </br>

                            <div>
                                <h3>Otros servicios</h3>
                                <div class="separator-dashed"></div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="sen_vial">Señalización de vialidades</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="sen_vial"
                                            :disabled="empty_urban_env.includes('sen_vial')"
                                            v-model="solicitud.urban_environment.road_marking">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="rec_basura">Recolección de basura</label>
                                            <select v-model="solicitud.urban_environment.garbage_collection" ref="rec_basura" class="form-control">
                                                <option value="Existe">Existe</option>
                                                <option value="No existe">No existe</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="nom_calles">Nomenclatura de calles</label>
                                            <select v-model="solicitud.urban_environment.nomenclature_streets" ref="nom_calles" class="form-control">
                                                <option value="Existe">Existe</option>
                                                <option value="No existe">No existe</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="porc_niv_infr">% de nivel de infraestructura</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="porc_niv_infr"
                                            :disabled="empty_urban_env.includes('porc_niv_infr')"
                                            v-model="solicitud.urban_environment.infrastructure_percent">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button v-if="showGuardarButton" type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                    <div class="bpb-card">
                        <div class="card-header">

                            <div class="row">
                                <div class="col-11">
                                    <div class="card-title">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                        </svg>
                                        Terreno
                                    </div>
                                </div>
                                <div class="col-1 d-flex justify-content-end">
                                    <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                    <div class="collapse" id="collapseTen" >
                        <form @submit="update_terreno">
                        <div class="card-body">
                            <div>
                                <h3>
                                    Características particulares
                                </h3>
                                <div class="separator-dashed"></div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group inline">
                                            <label for="calle_frente_or">Calle con frente y orientación</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="calle_frente_or"
                                            v-model="solicitud.property.terrain.street_front_orientation">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="calle_trans_or">Calle transversal y orientación</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="calle_trans_or"
                                            v-model="solicitud.property.terrain.cross_street_orientation">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="calles_lim_or">Calles limítrofes y orientación</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="calles_lim_or"
                                            v-model="solicitud.property.terrain.boundary_street_orientation">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="car_pan">Características panorámicas</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="car_pan"
                                            v-model="solicitud.property.terrain.panoramic_features">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="serv_restr">Servidumbre y/o restricciones</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="serv_restr"
                                            v-model="solicitud.property.terrain.easement_restrictions">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="ub_mz">Ubicación dentro de la manzana</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="ub_mz"
                                            v-model="solicitud.property.terrain.location_block">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="topografia">Topografía</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="topografia"
                                            v-model="solicitud.property.terrain.topography">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="conf_terreno">Configuración del terreno</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="conf_terreno"
                                            v-model="solicitud.property.terrain.terrain_configuration">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="tipo_vialidad">Tipo de vialidad</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="tipo_vialidad"
                                            v-model="solicitud.property.terrain.type_road">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Localización</label>
                                        <div id="map" style="height: 500px"></div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3>
                                    Información de la escritura
                                </h3>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="notaria">Notaría</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="notaria"
                                            v-model="solicitud.property.terrain.notary_name">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="num_escritura">Número de escritura</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="num_escritura"
                                            v-model="solicitud.property.terrain.scripture_number">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="fecha">Fecha</label>
                                            <input  type="date"
                                            class="form-control input-square"
                                            id="fecha"
                                            v-model="solicitud.property.terrain.date_scripture">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="notario">Notario</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="notario"
                                            v-model="solicitud.property.terrain.notary">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </br>
                            </br>
                            <div>
                                <h3>
                                    Medidas y colindancias del inmueble (a partir de la escritura)
                                </h3>
                                <div class="separator-dashed"></div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label  for="col_predio">Colindancias del predio</label>
                                            <input type="text"
                                            class="form-control input-square"
                                            id="col_predio"
                                            v-model="solicitud.property.terrain.boundary_state">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="col_inmueble">Colindancias del inmueble</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="col_inmueble"
                                            v-model="solicitud.property.terrain.boundary_property">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button v-if="showGuardarButton" type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Inmueble
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapseFive">
                        <form @submit="update_inmueble">
                        <div class="card-body">
                            <h3>
                                Características del Inmueble
                            </h3>
                            <br />
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group inline">
                                        <label for="desc_inmueble">Descripción del inmueble</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="desc_inmueble"
                                        v-model="solicitud.property.description">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="regime_inmueble">Régimen</label>
                                        <select v-model="solicitud.property.regime" ref="regime_inmueble" class="form-control">
                                            <option value="Propiedad privada">Propiedad privada</option>
                                            <option value="Propiedad en condominio">Propiedad en condominio</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="tipo_inmueble">Tipo de inmueble (clasificación SHF)</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="tipo_inmueble"
                                        disabled
                                        v-model="solicitud.property.type_property.description">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="wan_inmueble">Número de cuenta de agua</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="wan_inmueble"
                                        disabled
                                        v-model="solicitud.property.water_account_number">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="land_user_inmueble">Uso de suelo</label>
                                        <select v-model="solicitud.property.land_user" ref="land_user_inmueble" class="form-control">
                                            <option value="Habitacional">Habitacional</option>
                                            <option value="No habitacional">No habitacional</option>
                                            <option value="Oficina">Oficina</option>
                                            <option value="Comercio">Comercio</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="recamaras_inmueble">No. de Recámaras</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="recamaras_inmueble"
                                        disabled
                                        v-model="solicitud.property.num_bedrooms">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="estacionamiento_inmueble">No. de Estacionamientos</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="estacionamiento_inmueble"
                                        disabled
                                        v-model="solicitud.property.num_parkings">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group inline">
                                        <label for="banios_inmueble">No. de Baños</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="banios_inmueble"
                                        disabled
                                        v-model="solicitud.property.num_bathrooms">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group inline">
                                        <label for="cocina">Cocina</label>
                                        
                                        <div v-if="solicitud.property.has_kitchen">
                                        <input type="text" 
                                        class="form-control input-square"
                                        id="cocina" value="Con cocina"></div>
                                        
                                        <div v-else>
                                        <input type="text" 
                                        class="form-control input-square"
                                        id="cocina" value="Sin cocina"></div>
                                        
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group inline">
                                        <label for="owner_inmueble">Nombre del propietario</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="owner_inmueble"
                                        v-model="solicitud.property.owner_name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="pan_inmueble">Número de cuenta de la propiedad</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="pan_inmueble"
                                        v-model="solicitud.property.property_account_number">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="years_inmueble">Edad aproximada (años)</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="years_inmueble"
                                        disabled
                                        v-model="solicitud.property.years">
                                    </div>
                                </div>
                            </div>
                            <h3>Dirección</h3>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="direccion_inmueble">Dirección</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="direccion_inmueble"
                                        disabled
                                        v-model="solicitud.property.address">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="state_inmueble">Estado</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="state_inmueble"
                                        disabled
                                        v-model="solicitud.property.state">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="street_inmueble">Calle</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="street_inmueble"
                                        disabled
                                        v-model="solicitud.property.street">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="colonia_inmueble">Colonia</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="colonia_inmueble"
                                        disabled
                                        v-model="solicitud.property.colonia">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="latitud_inmueble">Latitud</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="latitud_inmueble"
                                        disabled
                                        v-model="solicitud.property.latitude">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="longitud_inmueble">Longitud</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="longitud_inmueble"
                                        disabled
                                        v-model="solicitud.property.longitude">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="municipio_inmueble">Municipio</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="municipio_inmueble"
                                        disabled
                                        v-model="solicitud.property.municipio">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="numero_inmueble">Número</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="numero_inmueble"
                                        disabled
                                        v-model="solicitud.property.number">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="cp_inmueble">Código Postal</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="cp_inmueble"
                                        disabled
                                        v-model="solicitud.property.postal_code">
                                    </div>
                                </div>
                            </div>
                            <h3>Otras características</h3>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="niv_cpo">Número de niveles de propiedad (Niv. cpo)</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="niv_cpo"
                                        v-model="solicitud.property.levels">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="niv_tipo">Número de niveles a valuar (Nivel Tipo)</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="niv_tipo"
                                        v-model="solicitud.property.evaluation_levels">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="avance_obra">Avance de obra</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="avance_obra"
                                        v-model="solicitud.property.total_score">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="estado_conservacion">Estado de conservación</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="estado_conservacion"
                                        v-model="solicitud.property.conservation_status">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="superficie_terreno">Superficie terreno</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="superficie_terreno"
                                        disabled
                                        v-model="solicitud.property.terrain.total_area">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="superficie_lote_privado">Superficie lote privado</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="superficie_lote_privado"
                                        v-model="solicitud.property.surface_private">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="indiviso">Indiviso</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="indiviso"
                                        v-model="solicitud.property.undivided_percent">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="terreno_proporcional">Terreno proporcional</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="terreno_proporcional"
                                        v-model="solicitud.property.proporcional_terrain">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="superficie_accesoria">Superficie accesoria</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="superficie_accesoria"
                                        v-model="solicitud.property.terrain.accessory_surface">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="superficie_construida">Superficie construida</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="superficie_construida"
                                        disabled
                                        v-model="solicitud.property.terrain.builded_surface">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="project_quality">Calidad del proyecto</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="project_quality"
                                        v-model="solicitud.project_quality">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group inline">
                                        <label for="vida_util_remanente">Vida util remanente</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="vida_util_remanente"
                                        disabled
                                        v-model="solicitud.vida_util_remanente">
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>


                <!--
                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Inmueble Anterior
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
            
                    <div class="collapse" id="collapseFive">
                        <form @submit="update_inmueble">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group inline">
                                        <label for="desc_inmueble">Descripción del inmueble</label>
                                        <input  type="text"
                                        class="form-control input-square"
                                        id="desc_inmueble"
                                        v-model="solicitud.property.description">
                                    </div>
                                </div>
                            </div>
                            </br>
                            </br>
                            <div>
                                <h3>
                                    Características de las construcciones privativas
                                </h3>
                                <div class="separator-dashed"></div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="tipo_inmueble">Tipo de inmueble (clasificación SHF)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="tipo_inmueble"
                                            disabled
                                            v-model="solicitud.property.type_property.description">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="shf">Clase (clasificación SHF)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="shf"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="uso_de_suelo">Uso de suelo</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="uso_de_suelo"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="superficie">Superficie</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="superficie"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </br>
                            </br>
                            <div>
                                <h3>
                                    Fuente de la determinación de la superficie
                                </h3>
                                <div class="separator-dashed"></div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="niv_cpo">Niv. Cpo</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="niv_cpo"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="niv_tipo">Nivel Tipo</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="niv_tipo"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="av_ob">Av. Ob (avance de obra)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="av_ob"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="vnr">VNR (Valor neto de reposición)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="vnr"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="vur">VUR (Vida útil remanente)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="vur"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="conservacion_shf">Estado de conservación (clasificación SHF)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="conservacion_shf"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="cal_proy">Calidad del proyecto</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="cal_proy"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="edad_aprox">Edad aproximada (años)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="edad_aprox"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="av_ob_gen">Avance de obra general</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="av_ob_gen"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="clas_gen_inm">Clase general del inmueble</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="clas_gen_inm"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="sup_terr">Superficie del terreno</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="sup_terr"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="sup_lot_priv">Superficie del lote privativo</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="sup_lot_priv"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="indiviso">Indiviso (%)</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="indiviso"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="terr_prop">Terreno proporcional</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="terr_prop"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="sup_vend">Superficie vendible</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="sup_vend"
                                            placeholder="">
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="sup_accesoria">Superficie accesoria</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="sup_accesoria"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group inline">
                                            <label for="sup_cons">Superficie construida</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="sup_cons"
                                            placeholder="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button v-if="showGuardarButton" type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                -->


                <!--
                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Mercado terreno
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapseSix">
                        <div class="card-body">

                        </div>
                        <div class="card-footer text-right">
                            <button type="button" class="btn btn-primary btn-border btn-round">
                                <span class="btn-label">
                                    <i class="fas fa-save"></i>
                                </span>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
                -->

                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Mercado Inmueble 
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapseNine">
                        <div class="card-body">
                            <div class="row">
                                <div class="table-ov col-sm-12">
                                    <DataGrid :config="dataGridConfig" :data="property_comparative">
                                    </DataGrid>
                                </div>
                                <div class="table-ov col-sm-12">
                                    <h3>Análisis de Homologación</h3>

                                    <DataGrid :config="dataGridConfig2" :data="property_comparative">
                                    </DataGrid>
                                </div>
                            </div>

                            <div class="row">
                                     
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="nombre_valuador">Superficie vendible</label>
                                            <input type="text"
                                            class="form-control input-square"
                                            id="nombre_valuador"
                                            disabled
                                            v-model="solicitud.sell_surface">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="enfoque_mercado">Valor comparativo del inmueble </label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="enfoque_mercado"
                                            disabled
                                            v-model="solicitud.comparative_market_value">
                                        </div>
                                    </div>
                                </div>

                        </div>
                            <!--
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            -->
                    </div>
                </div>

                <!--
                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Inmuebles venta
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapseNine">
                        <div class="card-body">
                            Valores
                        </div>
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button type="submit" class="btn secondary-btn">
                                            Guardar
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                -->

                <!--
                <div class="bpb-card">
                    <div class="card-header collapsed"
                    id="headingTwo"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven">

                        <div class="span-icon">
                            <div class="flaticon-coins"></div>
                        </div>
                        <div class="card-title">
                            COSTOS
                        </div>
                        <div class="span-mode"></div>
                    </div>
                    <div id="collapseSeven" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div class="card-body">
                            Valores
                        </div>
                        <div class="card-footer text-right">
                            <button type="button" class="btn btn-primary btn-border btn-round">
                                <span class="btn-label">
                                    <i class="fas fa-save"></i>
                                </span>
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
                -->
                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Fotografías
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapeSeven" aria-expanded="false" aria-controls="collapeSeven">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapeSeven">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-sm-12 col-md-4" v-for="(photo, index) in photos" :key="index">
                                    <div class="row justify-content-center">
                                        <div class="col-12 justify-content-center">
                                            <a :href="encodeURI(renderImgUrl+photo.url_imagen)" target="_blank">
                                                <img :src="photo.url_imagen" class="property-img">
                                            </a>
                                        </div>
                                        <br />
                                        <div class="col-12">
                                            <br />
                                            <center>
                                                <span ><i><b>Figura {{index + 1}}: </b>{{photo.section}}</i></span>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <!--
                            <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-2">
                                        <button type="submit" class="btn secondary-btn">
                                            Agregar Imagen
                                        </button>
                                    </div>
                                </div>
                            </div>
                            -->
                    </div>
                </div>

                <div class="bpb-card">
                    <div class="card-header">

                        <div class="row">
                            <div class="col-11">
                                <div class="card-title">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                    </svg>
                                    Conclusiones
                                </div>
                            </div>
                            <div class="col-1 d-flex justify-content-end">
                                <button class="btn btn-primary-outline" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="collapse" id="collapseEight">
                        <div class="card-body">
                                 <div class="row">
                                     
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="nombre_valuador">Valuador</label>
                                            <input type="text"
                                            class="form-control input-square"
                                            id="nombre_valuador"
                                            disabled
                                            v-model="solicitud.appraiser.user.name">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="enfoque_mercado">Enfoque de mercado</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="enfoque_mercado"
                                            disabled
                                            v-model="solicitud.comparative_market_value">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="clave">Clave</label>
                                            <input type="text"
                                            class="form-control input-square"
                                            id="clave"
                                            disabled
                                            v-model="solicitud.entity_credit_clave">
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group inline">
                                            <label for="entity_of_cred">Entidad otorgante del crédito</label>
                                            <input  type="text"
                                            class="form-control input-square"
                                            id="entity_of_cred"
                                            disabled
                                            v-model="solicitud.entity_credit">
                                        </div>
                                    </div>
                                    
                                    
                                <!-- <div class="col-md-12">
                                </div> -->
                            </div>
                        </div>
                            <!-- <div class="card-footer text-right">
                                <div class="row justify-content-end">
                                    <div class="col-3">
                                        <button type="submit" class="btn secondary-btn">
                                            Agregar Conclusión
                                        </button>
                                    </div>
                                </div>
                            </div> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="bpb-card">
                <div class="card-header">
                    <div class="card-head-row">
                        <div class="card-title">Detalle del registro</div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-8">
                            <h6 class="mb-1">Estatus:</h6>
                            <span class="text-muted" >{{ solicitud.status.name }}</span>
                        </div>
                        <div class="col-8">
                            <h6 class="mb-1">Creado:</h6>
                            <span class="text-muted" >{{ solicitud.created_by.email }}</span>
                        </div>
                        <div v-if="created_ago" class="col-4" align="end">
                            <small class="text-muted" >Hace {{created_ago}} días</small>
                        </div>
                    </div>

                </div>
            </div>

            <div class="bpb-card" v-if="showAsignarDiv">
                <div class="card-header">
                    <div class="card-head-row">
                        <div class="card-title">Asignación valuador</div>
                    </div>
                </div>
                <div v-if="isAdministrador" class="card-body">
                    <form @submit="asignar_valuador">
                        
                        <h6 class=" mb-1">Valuador actual:</h6>
                        <p v-if="selected_valuador.user.name">{{selected_valuador.user.name}}</p>
                        <p v-else>Sin asignar</p>
                        <br />

                        <h6 class=" mb-1">Asignar valuador:</h6>
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <select v-if="selected_valuador" @change="show_valuador_function($event)" ref="valuador" class="form-control">
                                    <option value="seleccionar">Selecciona un valuador</option>
                                    <option v-for="(valuador, index) in valuadores" :key="index" :value="valuador.user_id">{{ valuador.user.name }}</option>
                                </select>
                            </div>
                        </div>

                        <br />

                        <div class="row justify-content-center">
                            <div class="col-6">
                                <button :disabled="!show_valuador" class="btn secondary-btn" id="btn-asignar-valuador">
                                    Asignar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-else>
                    <div class="row justify-content-center">
                        No cuentas con el permiso para asignar el valuador
                    </div>
                </div>
            </div>

            <div v-if="showAprobarButton || showRechazarButton || showReprocesarButton" class="bpb-card">
                <div class="card-header">
                    <div class="card-head-row">
                        <div class="card-title">Acciones</div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-6">
                            <a v-if="showAprobarButton" :href="pdf_url" target="_blank" class="btn secondary-btn" id="btn-aprobar">
                                Aprobar Avalúo
                            </a>
                        </div>
                        <div class="col-6">
                            <button v-if="showRechazarButton" v-on:click="showConfirmaRechazar = true" class="btn complementary-btn" id="btn-rechazar">
                                Rechazar Avalúo
                            </button>
                        </div>
                        <div class="col-6">
                            <button v-if="showReprocesarButton" v-on:click="showConfirmaReprocesar = true" class="btn complementary-btn" id="btn-reprocesar">
                                Reprocesar Avalúo
                            </button>
                        </div>
                    </div>
                    <br />
                </div>
            </div>


            <!--
            <div v-if="showDescargarDiv" class="bpb-card">
            -->
            <div v-if="showDescargarDiv" class="bpb-card">
                <div class="card-header">
                    <div class="card-head-row">
                        <div class="card-title">PDF Firmado</div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-6">
                            <a class="btn secondary-btn" id="btn-descargar" :disabled="!solicitud.sign_aws_path" :href="solicitud.sign_aws_path">
                                Descargar
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showCitaDiv" class="bpb-card">
                <div class="card-header">
                    <div class="card-head-row">
                        <div class="card-title">Cita</div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-6">
                            <button v-on:click="showCitaModal = true" class="btn secondary-btn" id="btn-aprobar">
                                Mandar a cita
                            </button>
                        </div>
                        <div class="col-6">
                            <button v-on:click="showVisitaOcularModal = true" class="btn secondary-btn" id="btn-rechazar">
                                Visita ocular
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bpb-card">
                <div class="card-header">
                    <div class="card-head-row">
                        <div class="card-title">Documentos</div>
                    </div>
                </div>
                <div v-if="showDocumentsButtons" class="card-body">
                    <form @submit="descarga_documento">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <select v-if="documentos" @change="show_documento_fun($event)" class="form-control">
                                    <option>seleccionar</option> 
                                    <option v-for="(doc, index) in documentos" :key="index" :value="doc.documento_id">{{doc.nombre_real ? doc.nombre_real : doc.nombre}}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <button class="btn secondary-btn" id="btn-mostrar-doc" :disabled="!show_documento">
                                    Descargar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div v-else class="card-body">
                    <div class="row justify-content-center">
                        No existen documentos para esta solicitud
                    </div>
                </div>
            </div>
        </div>
    </div>
	<br />

    <Modal v-if="showCitaModal" :options="{width: '40vw'}">
        <div class="title">
            <h3>Mandar a cita</h3>
        </div>
        <div class="body">
            <form @submit="asignar_cita">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="no_solicitud">Título del evento</label>
                            <input  type="text"
                            class="form-control input-square"
                            id="no_solicitud"
                            required
                            v-model="mandar_cita.title">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="desc_evento">Descripción del evento</label>
                            <input  type="text"
                            class="form-control input-square"
                            id="desc_evento"
                            required
                            v-model="mandar_cita.descript">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="prop_avaluo">Tipo de evento</label>
                            <input  type="text"
                            class="form-control input-square"
                            id="prop_avaluo"
                            disabled
                            required
                            v-model="mandar_cita.event">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="fecha_evento">Fecha de evento</label>
                            <input  type="date"
                            class="form-control input-square"
                            id="fecha_evento"
                            required
                            v-model="mandar_cita.date">
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="dir_evento">Dirección del evento</label>
                            <input  type="text"
                            class="form-control input-square"
                            id="dir_evento"
                            required
                            disabled
                            v-model="solicitud.property.address">
                        </div>

                    </div>

                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="valuador_evento">Valuador</label>
                            <input  type="text"
                            class="form-control input-square"
                            id="valuador_evento"
                            required
                            disabled
                            v-model="selected_valuador.user.name">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="dir_evento">Solicitante</label>
                            <input  type="text"
                            class="form-control input-square"
                            id="dir_evento"
                            required
                            disabled
                            v-model="solicitud.applicant.full_name">
                        </div>

                    </div>

                    <div class="col-md-6">
                        <div class="form-group inline">
                            <label for="valuador_evento">Encargado</label>
                            <input  type="text"
                            class="form-control input-square"
                            id="valuador_evento"
                            required
                            disabled
                            value="María Fernanda Rodríguez Ramírez">
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row justify-content-end">
                    <div class="col-6">
                        <button type="button" v-on:click="showCitaModal = false" class="btn complementary-btn">
                            Cancelar
                        </button>
                    </div>
                    <div class="col-6">
                        <button type="submit" class="btn secondary-btn">
                            Generar cita
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </Modal>

    <Modal v-if="showVisitaOcularModal" :options="{width: '40vw'}">
        <div class="title">
            <h3>Visita ocular</h3>
        </div>
        <div class="body">
            <form @submit="asignar_valuador_visita_ocular">

                <!-- TODO combo anidado al seleccionar zona se muestran solo los que  -->

                <h6 class=" mb-1">Zona:</h6>

                <br />

                <div class="row justify-content-center">
                    <div class="col-12">
                        <select v-if="zonas" @change="filtrar_valuadores_zonificados($event)" class="form-control">
                            <option>Selecciona una zona</option>
                            <option v-for="(zona, index) in zonas" :key="index" :value="zona.name" >{{ zona.name }}</option>
                        </select>
                    </div>
                </div>

                <hr>

                
                <h6 class=" mb-1">Valuador:</h6>

                <br />

                <div class="row justify-content-center">
                    <div class="col-12">
                        <select v-if="valuadores_zonificados" @change="show_valuador_zonificado_function($event)" ref="valuador_zonificado" class="form-control">
                            <option value="seleccionar">Selecciona un valuador</option>
                            <option v-for="(valuador, index) in valuadores_zonificados" :key="index" :value="valuador.user_id" >{{ valuador.user.name }}</option>
                        </select>
                    </div>
                </div>

                <hr>

                <div class="row justify-content-center">
                    <div class="col-6">
                        <button type="button" v-on:click="showVisitaOcularModal = false" class="btn complementary-btn">
                            Cerrar
                        </button>
                    </div>
                    <div class="col-6">
                        <button type="submit" class="btn secondary-btn" id="btn-asignar-valuador" :disabled="!show_valuador_zonificado">
                            Asignar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </Modal>

    <Modal v-if="showAprobarAvaluoModal" :options="{width: '40vw'}">
        <div class="title">
            <h3>Aprobar Avaluo</h3>
        </div>
        <div class="body">
            <form @submit="update_generales">
                <div class="card-footer text-right">

                    <div class="row">
                        <div class="col-md-12">
                            <iframe :src="pdf_url"></iframe>
                        </div>
                    </div>


                    <div class="row justify-content-end">
                        <div class="col-6">
                            <button type="button" v-on:click="showAprobarAvaluoModal = false" class="btn complementary-btn">
                                Cancelar
                            </button>
                        </div>
                        <!--
                        <div class="col-6">
                            <button type="submit" class="btn secondary-btn">
                                Aprobar avaluo
                            </button>
                        </div>
                        -->
                    </div>
                </div>
            </form>
        </div>
    </Modal>

    <Modal v-if="showConfirmaRechazar" :options="{width: '40vw'}">
        <div class="title">
            <h3>Rechazar avalúo</h3>
        </div>
        <div class="body">
            <form @submit="rechazar_avaluo">
                <div class="form-group inline">
                    <label for="indiviso">Comentario</label>
                    <input  type="text"
                    class="form-control input-square"
                    id="comentario_rechazo"
                    required="required"
                    placeholder="Colocar motivo de rechazo"
                    v-model="motivo_rechazo">
                </div>
                <div class="row justify-content-center">
                    <div class="col-6">
                        <button type="button" v-on:click="showConfirmaRechazar = false" class="btn complementary-btn">
                            Cancelar
                        </button>
                    </div>
                    <div class="col-6">
                        <button type="submit" class="btn secondary-btn" id="btn-asignar-valuador">
                            Rechazar
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </Modal>

    <Modal v-if="showConfirmaReprocesar" :options="{width: '40vw'}">
        <div class="title">
            <h3>Reprocesar solicitud</h3>
        </div>
        <div class="body">
            <div class="row justify-content-center">
                <div class="col-6">
                    <button type="button" v-on:click="showConfirmaReprocesar = false" class="btn complementary-btn">
                        Cancelar
                    </button>
                </div>
                <div class="col-6">
                    <button v-on:click="reprocesar_avaluo()" class="btn secondary-btn" id="btn-asignar-valuador">
                        Reprocesar
                    </button>
                </div>
            </div>
        </div>
    </Modal>

	</div>
</template>

<script type="text/javascript">

import config from '@/apps/avaluodigital/config'

import Modal from "@/components/Modal";
import DataGrid from '@/components/DataGridV2'

import gmaps from '@/helpers/gmaps'
import api from '@/apps/avaluodigital/api/avaluo'
import api_formalizacion from '@/apps/avaluodigital/api/formalizacion'

import moment from 'moment'

export default {
  components: {
    Modal,
    DataGrid,
    // TravelMap
  },
  data: function () {
    return {
        showCitaModal: false
        ,showAprobarAvaluoModal: false
        ,showVisitaOcularModal: false
        ,showConfirmaRechazar: false
        ,showConfirmaReprocesar: false
        ,created_ago: null
        ,renderImgUrl: config.image_render_url
        ,avaluoAi360Url: config.avaluo_ai360_url
        ,mandar_cita: {
            title: null,
            descript: null,
            event: null,
            date: null,
            location: null,
            attendees: []
        }
        ,solicitud: {
            applicant: {
                address: null
                ,created_by: {
                    email: null
                    ,id: null
                    ,name: null
                    ,position: {
                        id: null
                        ,is_active: null
                        ,name: null
                    }
                    ,position_id: null
                }
                ,created_by_id: null
                ,created_date: null
                ,curp: null
                ,full_name: null
                ,id: null
                ,rfc: null
                ,updated_by: {
                    email: null
                    ,id: null
                    ,name: null
                }
                ,updated_by_id: null
                ,updated_date: null
            }
            ,applicant_id: null
            ,appraiser: {
                autorizacion_shf: null
                ,cedula_posgrado: null
                ,cedula_profresional: null
                ,certificado_shf: null
                ,created_by: {
                    email: null
                    ,id: null
                    ,name: null
                    ,position: {
                        id: null
                        ,is_active: null
                        ,name: null
                    }
                    ,position_id: null
                }
                ,created_by_id: null
                ,created_date: null
                ,posgrado: null
                ,profesion: null
                ,registro_fiscal: null
                ,updated_by: null
                ,updated_by_id: null
                ,updated_date: null
                ,user: {
                    email: null
                    ,id: null
                    ,name: null
                    ,position: {
                        id: null
                        ,is_active: null
                        ,name: null
                    }
                    ,position_id: null
                }
                ,user_id: null
                ,zone: {
                    id: null
                    ,name: null
                    ,description: null
                    ,is_active: false
                }
                ,unit: {
                    id: null
                    ,name: null
                    ,email: null
                    ,is_active: false
                }
            }
            ,appraiser_id: null
            ,created_by: {
                email: null
                ,id: null
                ,name: null
                ,position: {
                    id: null
                    ,is_active: null
                    ,name: null
                }
                ,position_id: null
            }
            ,created_by_id: null
            ,created_date: null
            ,date_opinion: null
            ,entity_credit: null
            ,entity_credit_clave: null
            ,enfoque_mercado: null
            ,cocina: null
            ,sell_surface: null
            ,comparative_market_value: null
            ,vida_util_remanente: null
            ,project_quality: null
            ,external_id: null
            ,id: null
            ,object_appraisal: null
            ,photos: []
            ,property: {
                address: null
                ,class_property_id: null
                ,created_by: {
                    email: null
                    ,id: null
                    ,name: null
                    ,position: {
                        id: null
                        ,is_active: null
                        ,name: null
                    }
                    ,position_id: null
                }
                ,created_by_id: null
                ,created_date: null
                ,external_id: null
                ,id: null
                ,land_user: null
                ,latitude: null
                ,longitude: null
                ,num_bathrooms: null
                ,num_bedrooms: null
                ,num_parkings: null
                ,owner_name: null
                ,property_account_number: null
                ,regime: null
                ,terrain: {
                    boundary_property: null
                    ,boundary_state: null
                    ,boundary_street_orientation: null
                    ,builded_surface: null
                    ,created_by: {
                        email: null
                        ,id: null
                        ,name: null
                        ,position: {
                            id: null
                            ,is_active: null
                            ,name: null
                        }
                        ,position_id: null
                    }
                    ,created_by_id: null
                    ,created_date: null
                    ,cross_street_orientation: null
                    ,date_scripture: null
                    ,easement_restrictions: null
                    ,id: null
                    ,location_block: null
                    ,notary: null
                    ,notary_name: null
                    ,panoramic_features: null
                    ,scripture_number: null
                    ,street_front_orientation: null
                    ,terrain_configuration: null
                    ,topography: null
                    ,total_area: null
                    ,type_road: null
                    ,updated_by: {
                        email: null
                        ,id: null
                        ,name: null
                    }
                    ,updated_by_id: null
                    ,updated_date: null
                }
                ,terrain_id: 1
                ,type_property: {
                    description: null
                    ,id: null
                    ,is_active: null
                    ,name: null
                }
                ,type_property_id: null
                ,updated_by: {
                    email: null
                    ,id: null
                    ,name: null
                    ,position: {
                        id: null
                        ,is_active: null
                        ,name: null
                    }
                    ,position_id: null
                }
                ,updated_by_id: null
                ,updated_date: null
                ,water_account_number: null
            }
            ,property_id: null
            ,purpose: null
            ,status: {
                description: null
                ,id: null
                ,is_active: null
                ,name: null
            }
            ,status_id: null
            ,updated_by: {
                email: null
                ,id: null
                ,name: null
                ,position: {
                    id: null
                    ,is_active: null
                    ,name: null
                }
                ,position_id: null
            }
            ,updated_by_id: null
            ,updated_date: null
            ,property_comparative: []
            ,urban_environment: {
                access_roads: null
                ,allowable_levels: null
                ,benches: null
                ,connection_electrical: null
                ,created_by: {
                    email: null
                    ,id: null
                    ,name: null
                    ,position: {
                        id: null
                        ,is_active: null
                        ,name: null
                    }
                    ,position_id: null
                }
                ,created_by_id: null
                ,created_date: null
                ,density_report: null
                ,driking_water: null
                ,garbage_collection: null
                ,id: null
                ,infrastructure_available: null
                ,infrastructure_level: null
                ,naturalgas: null
                ,network_wastewater: null
                ,nomenclature_streets: null
                ,population_density: null
                ,public_lighting: null
                ,rainwater_drainage_network: null
                ,road_marking: null
                ,roads: null
                ,side_dishes: null
                ,socieconomic_level: null
                ,updated_by: {
                    email: null
                    ,id: null
                    ,name: null
                }
                ,updated_by_id: null
                ,updated_date: null
                ,use_constructions: null
                ,zone_classification: null
            }
            ,urban_environment_id: null
            ,pdf_token: null
            ,sign_aws_path: null
        }
        ,mandar_cita: {
            title: null,
            descript: null,
            event: "fecha_cita",
            date: "2021-10-05T14:48",
            location: null,
            attendees: [
                {
                    name: null,
                    email: null
                }
            ]
        }
        ,solicitud_para_llenar: null
        ,isAdministrador: this.$auth.can('avaluo_digital','administrador') != false
        ,administrador: this.$auth.can('avaluo_digital','administrador')
        ,perito: this.$auth.can('avaluo_digital','perito')
        ,valuadores: []
        ,valuadores_zonificados: []
        ,selected_documento: null
        ,usar_direccion: false
        ,direccion_tmp: null
        ,empty_urban_env: []
        ,documentos: []
        ,zonas: []
        ,selected_valuador: {
            user: {
                id: null
            }
        }
        ,marker: null
        ,map: null
        ,property_comparative: []
        ,photos: []
        ,pdf_url: null
        ,showAprobarButton: false
        ,showRechazarButton: false
        ,showReprocesarButton: false
        ,showDescargarDiv: false
        ,showAsignarDiv: false
        ,showCitaDiv: false
        ,showGuardarButton: false
        ,showDocumentsButtons: false
        ,valija_id: null
        ,doc_escritura: null
        ,doc_predial: null
        ,doc_agua: null
        ,pdf_token: null
        ,show_valuador_zonificado: false
        ,show_valuador: false
        ,show_documento: false
        ,motivo_rechazo: null
        ,dataGridConfig: {
            name: 'enfoque_fisico'
            ,select_items: false
            ,search: false
            ,cols: {
                id: {
                    text: 'No.'
                    ,ordeable: false
                }
                ,colonia: {
                    text: 'Ubicación de la oferta (Comparables)'
                    ,ordeable: false
                }
                ,years: {
                    text: 'Edad'
                    ,ordeable: false
                }
                ,state: {
                    text: 'Conservación'
                    ,ordeable: false
                }
                ,phone: {
                    text: 'Teléfono'
                    ,ordeable: false
                }
                ,url_anuncio: {
                    text: 'Fuente'
                    ,ordeable: false
                }
            }
            ,mutators: {
                url_anuncio: function(val) {
                    return `<a href="${val}" target="_blank">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                        </svg>
                    </a>`;
                }
            }
        }
        ,dataGridConfig2: {
            name: 'enfoque_fisico2'
            ,select_items: false
            ,search: false
            ,cols: {
                id: {
                    text: 'No.'
                    ,ordeable: false
                }
                ,price: {
                    text: 'Precio de venta inmuebles'
                    ,ordeable: false
                }
                // ,surface_terrain: {
                //     text: 'Superficie m2 (terreno)'
                //     ,ordeable: false
                // }
                ,surface: {
                    text: 'Superficie m2 (construcción)'
                    ,ordeable: false
                }
                ,property_type: {
                    text: 'Tipo de Inmueble'
                    ,ordeable: false
                }
                ,coordenadas: { // TODO
                    text: 'Coordenadas'
                    ,ordeable: false
                }
                ,cp: {
                    text: 'CP'
                    ,ordeable: false
                }
                // ,builded_surface: {
                //     text: 'Superficie construida'
                //     ,ordeable: false
                // }
                ,num_bedrooms: {
                    text: 'Recamaras'
                    ,ordeable: false
                }
                ,num_bathrooms: {
                    text: 'Baños'
                    ,ordeable: false
                }
                ,num_parkings: {
                    text: 'Estacionamientos'
                    ,ordeable: false
                }
                ,homologued_price: {
                    text: 'Precio Homologado'
                    ,ordeable: false
                }
                
            }
            ,mutators: {
                url_anuncio: function(val) {
                    return `<a href="${val}">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-up-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0v-6z"/>
                        </svg>
                    </a>`;
                }
                ,homologued_price: function(val,row) {
                    return `<span>${row.homologate_price}</span>`
                }
            }
        }
    }
  },
  mounted: function ()  {
    this.obtener_solicitud();
    this.obtener_documentos();
  },
  methods: {
        swap_token: async function(){
            try{
                const avaluoRps = await api.obtener_token(this.$auth.getAuth())
                const tkn = avaluoRps.data.payload.token
                this.$store.state.tokenAD = avaluoRps.data.payload.token
                
                await api.setGlobalToken(tkn);
            }catch(err){
                this.$store.state.tokenAD = null;
                throw err;
            }
        }
      ,obtener_solicitud: async function() {
          try{
            await this.swap_token();
            let response = (await api.consulta_solicitud(this.$route.params.solicitud)).data;
            this.solicitud_para_llenar = JSON.parse(response.payload)
            // this.$log.debug(this.solicitud_para_llenar)
            this.fill_solicitud_fields(this.solicitud_para_llenar, '')
            if (this.solicitud_para_llenar.appraiser_visit) {
                this.selected_valuador = this.solicitud_para_llenar.appraiser_visit;
            }else if (this.solicitud_para_llenar.appraiser) {
                this.selected_valuador = this.solicitud_para_llenar.appraiser;
            }
            if (this.solicitud_para_llenar.property_comparative) {
                this.property_comparative = this.solicitud_para_llenar.property_comparative.map(function(pc){
                    pc.coordenadas = `${pc.latitude}, ${pc.longitude}`;
                    return pc;
                });
            }
            if (this.solicitud_para_llenar.photos) {
                this.photos = this.solicitud_para_llenar.photos;
            }

            if (this.solicitud_para_llenar.created_date) {
                let fecha = moment(this.solicitud_para_llenar.created_date)
                let hoy = moment()
                this.created_ago = hoy.diff(fecha, 'days')
            }

            if(this.solicitud_para_llenar.pdf_token){
                let email = await this.get_email()
                this.pdf_url = `${this.avaluoAi360Url}/avaluos/${this.solicitud.id}/preview/?token=${this.solicitud_para_llenar.pdf_token}&email=${email}`
            }

            const google = await gmaps();
                let lat = this.solicitud.property.latitude
                let lng = this.solicitud.property.longitude

                this.map = new google.maps.Map(document.getElementById('map'), {
                    center: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng)
                    }
                    ,zoom: 17
                })

                this.marker = new google.maps.Marker({
                    position: new google.maps.LatLng(parseFloat(lat), parseFloat(lng)),
                    map: this.map,
                    title: 'Localización del terreno'
                });

            // 1 COMPLETADO
            // 2 EN REVISIÓN
            // 3 ASIGNADO
            // 4 EN PROCESO
            // 5 DETENIDO
            // 6 EN CAPTURA (SIN USAR)
            // 7 RECHAZADO
            // 8 APROBADO

            // Se oculta todo por default
            this.showAprobarButton = false
            this.showRechazarButton = false
            this.showDescargarDiv = false
            this.showAsignarDiv = false
            this.showReprocesarButton = false
            this.showGuardarButton = false
            
            //Se muestran las opciones por estatus
            if(this.solicitud_para_llenar.status_id == 3 ){
                this.showAprobarButton = true
                this.showRechazarButton = true
                this.showCitaDiv = true
                this.showGuardarButton = true
            }else if (this.solicitud_para_llenar.status_id == 1){
                this.showAsignarDiv = true
            }else if(this.solicitud_para_llenar.status_id == 7){
                this.showCitaDiv = true
                this.showAsignarDiv = true
                this.showReprocesarButton = true
            }else if(this.solicitud_para_llenar.status_id == 8){
                this.showDescargarDiv = true
            }else if(this.solicitud_para_llenar.status_id == 5){
                this.showReprocesarButton = true
            }
            await this.obtener_valuadores();
            await this.obtener_zonas();
          }catch(err){
              this.$helper.showAxiosError(err, 'Error');
          }
      }
      ,fill_solicitud_fields: function(obj, stack){
        for (const property in obj) {
            if (obj.hasOwnProperty(property)) {

                if (typeof obj[property] == "object" && !Array.isArray(obj[property])) {
                    this.fill_solicitud_fields(obj[property], stack + '.' + property);
                } else {
                    try {
                        eval(
                            `
                            if (this.solicitud_para_llenar${stack}.${property} !== null) {
                                this.solicitud${stack}.${property} = this.solicitud_para_llenar${stack}.${property}
                            }
                            `
                        )
                    } catch (error) {
                        // this.$log.debug(`El campo no está mapeado. this.solicitud${stack}.${property} = this.solicitud_para_llenar${stack}.${property}`)
                    }
                }
            }
        }
      }
      ,change_dir_avaluo: function(e) {
            if (this.direccion_tmp == null){
                this.direccion_tmp = this.solicitud.applicant.address
            }
            if (this.usar_direccion){
                this.solicitud.applicant.address = this.solicitud.property.address
            } else {
                this.usar_direccion = false
                this.solicitud.applicant.address = this.direccion_tmp
            }
        }
      ,obtener_valuadores : async function() {
          try{
            let response = (await api.consulta_valuadores()).data;
            this.valuadores = JSON.parse(response.payload)
            this.check_urban_env();
          }catch(err){
              this.$helper.showAxiosError(err, 'Error');
          }
      }
      ,obtener_zonas : async function() {
          try{
            let response = (await api.consulta_zonas()).data;
            this.zonas = JSON.parse(response.payload)
          }catch(err){
              this.$helper.showAxiosError(err, 'Error');
          }
      },
      rechazar_avaluo : async function(e) {  
        try{
            e.preventDefault();
            let response = await api.rechaza_solicitud(this.solicitud.id, this.motivo_rechazo)
            this.showConfirmaRechazar = false;
            this.$helper.showMessage('Rechazo enviado');

            // Refresca solicitud
			this.obtener_solicitud();
        }catch(err){
            this.showConfirmaRechazar = false;
            this.$helper.showAxiosError(err, 'Error');
        }
      },
      reprocesar_avaluo : async function() {  
        try{
            let response = await api.start_solicitud(this.solicitud.id);
            this.$helper.showMessage('Reproceso enviado');
            this.showConfirmaReprocesar = false;

            // Refresca solicitud
			this.obtener_solicitud();
        }catch(err){
            this.showConfirmaReprocesar = false;
            this.$helper.showAxiosError(err, 'Error');
        }
      },
      obtener_documentos : async function(){
          try {
            let response = await api_formalizacion.consulta_documentos(this.$auth.getAuth(),
                                this.$route.params.solicitud);
            if (response.success){
                this.documentos = []
                const consultaDocs = response.data
                if (consultaDocs){
                
                    consultaDocs.forEach(documento => {
                        this.documentos.push(documento); 
                    });
    
                    if (this.documentos.length < 1){
                        this.showDocumentsButtons = false
                    } else {
                        this.showDocumentsButtons = true;
                    }
                }
            } else {
                this.showDocumentsButtons = false
                const customError = {
                    response:{
                        data: {
                            message: 'No se pudo obtener los documentos de la solicitud'
                    }}}
                this.$log.error(response.error.message);
                this.showDocumentsButtons = false;
                this.$helper.showAxiosError(customError, 'Error');
            }
          } catch(err){
              this.$log.error(err);
              this.showDocumentsButtons = false;
              this.$helper.showAxiosError(err, 'Error');
          }
      },
      obtener_doc_escritura: async function(){
          if(this.doc_escritura){
              try{
                let response = await api_formalizacion.consulta_documento(this.$route.params.solicitud, this.doc_escritura.valija_id, this.doc_escritura.id)
                //TODO Show document
              }catch(err){
                  this.$helper.showAxiosError(err, 'Error');
              }
          }
      },
      obtener_doc_predial: async function(){
          if(this.doc_predial){
              try{
                let response = await api_formalizacion.consulta_documento(this.$route.params.solicitud, this.doc_predial.valija_id, this.doc_predial.id)
                //TODO Show document
              }catch(err){
                  this.$helper.showAxiosError(err, 'Error');
              }
          }
      },
      obtener_doc_agua: async function(){
          if(this.doc_agua){
              try{
                let response = await api_formalizacion.consulta_documento(this.$route.params.solicitud, this.doc_agua.valija_id, this.doc_agua.id)
                //TODO Show document
              }catch(err){
                  this.$helper.showAxiosError(err, 'Error');
              }
          }
      },
      update_generales: async function(e) {
        try{
            e.preventDefault();
            let payload = {
                purpose: this.solicitud.purpose,
                object_appraisal: this.solicitud.object_appraisal,
                entity_credit: this.solicitud.entity_credit, 
                regime: this.solicitud.property.regime,
                land_user: this.solicitud.property.land_user,
                water_account_number: this.solicitud.property.water_account_number,
                property_account_number: this.solicitud.property.property_account_number,
                type_property_id: this.solicitud.property.type_property_id,
                owner_name: this.solicitud.property.owner_name,
            }

            let response = (await api.update_solicitud(this.solicitud.id, payload)).data;
            this.$helper.showMessage('Actualización realizada satisfactoriamente');

            // Refresca solicitud
			this.obtener_solicitud();
        }catch(err){
            this.$helper.showAxiosError(err, 'Error');
        }
      }
      ,update_solicitante: async function(e) {
          try{
            e.preventDefault();
            let payload = {
                full_name: this.solicitud.applicant.full_name,
                address: this.solicitud.applicant.address,
                rfc: this.solicitud.applicant.rfc,
                curp: this.solicitud.applicant.curp
            }
            let response = (await api.update_solicitante(this.solicitud.applicant.id, payload)).data;
            this.$helper.showMessage('Actualización realizada satisfactoriamente');

            // Refresca solicitud
			this.obtener_solicitud();
          }catch(err){
            this.$helper.showAxiosError(err, 'Error');
          }
      }
      ,update_inmueble: async function(e) {
          try{
            e.preventDefault();
            let payload = {
                // address: solicitud.property.address,
                // colonia: solicitud.property.colonia,
                // land_user: solicitud.property.land_user,
                // latitude: solicitud.property.latitude,
                // longitude: solicitud.property.longitude,
                // municipio: solicitud.property.municipio,
                // num_bathrooms: solicitud.property.num_bathrooms,
                // num_bedrooms: solicitud.property.num_bedrooms,
                // num_parkings: solicitud.property.num_parkings,
                // number: solicitud.property.number,
                description: this.solicitud.property.description,
                owner_name: this.solicitud.property.owner_name,
                // postal_code: solicitud.property.postal_code,
                property_account_number: this.solicitud.property.property_account_number,
                regime: this.solicitud.property.regime,
                land_user: this.solicitud.property.land_user,
                conservation_status: this.solicitud.property.conservation_status,
                surface_private: this.solicitud.property.surface_private,
                evaluation_levels: this.solicitud.property.evaluation_levels,
                levels: this.solicitud.property.levels,
                total_score: this.solicitud.property.total_score,
                undivided_percent: this.solicitud.property.undivided_percent,
                proporcional_terrain: this.solicitud.property.proporcional_terrain,
                // state: solicitud.property.state,
                // street: solicitud.property.street,
                // water_account_number: solicitud.property.water_account_number,
                // years: solicitud.property.years,
            }
            let response = (await api.update_inmueble(this.solicitud.property_id, payload)).data;
            this.$helper.showMessage('Actualización realizada satisfactoriamente');

            // Refresca solicitud
			this.obtener_solicitud();
          }catch(err){
            this.$log.error('Error: ', err)
            this.$helper.showAxiosError(err, 'Error');
          }
      }
      ,update_terreno: async function(e) {
          try{  
                e.preventDefault();
                let payload = {
                notary: this.solicitud.property.terrain.notary,
                scripture_number: this.solicitud.property.terrain.scripture_number,
                date_scripture: this.solicitud.property.terrain.date_scripture,
                notary_name: this.solicitud.property.terrain.notary_name,
                topography: this.solicitud.property.terrain.topography,
                terrain_configuration: this.solicitud.property.terrain.terrain_configuration,
                location_block: this.solicitud.property.terrain.location_block,
                type_road: this.solicitud.property.terrain.type_road,
                // total_area: this.solicitud.property.terrain.total_area,
                // builded_surface: this.solicitud.property.terrain.builded_surface,
                street_front_orientation: this.solicitud.property.terrain.street_front_orientation,
                cross_street_orientation: this.solicitud.property.terrain.cross_street_orientation,
                boundary_street_orientation: this.solicitud.property.terrain.boundary_street_orientation,
                panoramic_features: this.solicitud.property.terrain.panoramic_features,
                easement_restrictions: this.solicitud.property.terrain.easement_restrictions,
                boundary_state: this.solicitud.property.terrain.boundary_state,
                boundary_property: this.solicitud.property.terrain.boundary_property,
            }
            let response = (await api.update_terreno(this.solicitud.property.terrain.id, payload)).data;
            this.$helper.showMessage('Actualización realizada satisfactoriamente');

            // Refresca solicitud
			this.obtener_solicitud();
          }catch(err){
            this.$helper.showAxiosError(err, 'Error');
          }
      }
      ,update_urban_env: async function(e) {
        try{
            e.preventDefault();
            let payload = {
              zone_classification: this.solicitud.urban_environment.zone_classification,
              allowable_levels: this.solicitud.urban_environment.allowable_levels,
              use_constructions: this.solicitud.urban_environment.use_constructions,
              population_density: this.solicitud.urban_environment.population_density,
              density_report: this.solicitud.urban_environment.density_report,
              socieconomic_level: this.solicitud.urban_environment.socieconomic_level,
              access_roads: this.solicitud.urban_environment.access_roads,
              infrastructure_available: this.solicitud.urban_environment.infrastructure_available,
              roads: this.solicitud.urban_environment.roads,
              side_dishes: this.solicitud.urban_environment.side_dishes,
              public_lighting: this.solicitud.urban_environment.public_lighting,
              driking_water: this.solicitud.urban_environment.driking_water,
              network_wastewater: this.solicitud.urban_environment.network_wastewater,
              benches: this.solicitud.urban_environment.benches,
              naturalgas: this.solicitud.urban_environment.naturalgas,
              surveillance: this.solicitud.urban_environment.surveillance,
              connection_electrical: this.solicitud.urban_environment.connection_electrical,
              rainwater_drainage_network: this.solicitud.urban_environment.rainwater_drainage_network,
              road_marking: this.solicitud.urban_environment.road_marking,
              garbage_collection: this.solicitud.urban_environment.garbage_collection,
              nomenclature_streets: this.solicitud.urban_environment.nomenclature_streets,
              infrastructure_level: this.solicitud.urban_environment.infrastructure_level,
            }

            let response = (await api.update_urban_env(this.solicitud.urban_environment.id, payload)).data;
            this.$helper.showMessage('Actualización realizada satisfactoriamente');

            // Refresca solicitud
			this.obtener_solicitud();
        }catch(err){
            this.$helper.showAxiosError(err, 'Error');
        }
      }
      ,check_urban_env: async function() {
        
        this.empty_urban_env = []
        if (this.solicitud.urban_environment.allowable_levels){
            this.empty_urban_env.push('niv_perm')
        }
        if (this.solicitud.urban_environment.use_constructions){
            this.empty_urban_env.push('uso_const')
        }
        if (this.solicitud.urban_environment.population_density){
            this.empty_urban_env.push('den_pob')
        }
        if (this.solicitud.urban_environment.density_report){
            this.empty_urban_env.push('rep_den')
        }
        if (this.solicitud.urban_environment.socieconomic_level){
            this.empty_urban_env.push('niv_soc')
        }
        if (this.solicitud.urban_environment.access_roads){
            this.empty_urban_env.push('vias_acceso')
        }
        if (this.solicitud.urban_environment.infrastructure_level){
            this.empty_urban_env.push('infr_disp')
        }
        if (this.solicitud.urban_environment.roads){
            this.empty_urban_env.push('vialidades')
        }
        if (this.solicitud.urban_environment.side_dishes){
            this.empty_urban_env.push('guarniciones')
        }
        if (this.solicitud.urban_environment.public_lighting){
            this.empty_urban_env.push('al_publico')
        }
        if (this.solicitud.urban_environment.driking_water){
            this.empty_urban_env.push('red_dist_agua')
        }
        if (this.solicitud.urban_environment.network_wastewater){
            this.empty_urban_env.push('red_rec_ag_res')
        }
        if (this.solicitud.urban_environment.rainwater_drainage_network){
            this.empty_urban_env.push('red_drenaje')
        }
        if (this.solicitud.urban_environment.connection_electrical){
            this.empty_urban_env.push('acom_inm_sum_el')
        }
        if (this.solicitud.urban_environment.road_marking){
            this.empty_urban_env.push('sen_vial')
        }
        if (this.solicitud.urban_environment.infrastructure_percent){
            this.empty_urban_env.push('porc_niv_infr')
        }
      }
      ,asignar_valuador: async function(e) {
          e.preventDefault();
          let valuador = this.$refs["valuador"];
          let payload = {
              appraiser_id: valuador.value
          }
          try{
            let response = (await api.update_solicitud(this.solicitud.id, payload)).data;
            // this.$log.debug(response)
            this.$helper.showMessage('Asignación realizada satisfactoriamente');

            // Refresca solicitud
			this.obtener_solicitud();
          }catch(err){
            this.$helper.showAxiosError(err, 'Error');
          }
      }
      ,asignar_cita: async function(e) {
          e.preventDefault();
          this.mandar_cita.attendees = [
              {
                name: this.selected_valuador.user.name,
                email: this.selected_valuador.user.email
              },
              {
                name: this.solicitud.applicant.full_name,
                email: this.solicitud.applicant.email ? this.solicitud.applicant.email : "mfrodriguez@bienparabien.com"
              },
              {
                name: "María Fernanda Rodríguez Ramírez", //Encargado bpb
                email: "mfrodriguez@bienparabien.com"
              }
          ]
          this.mandar_cita.date = moment(this.mandar_cita.date).format('YYYY-MM-DDThh:mm');
          this.mandar_cita.location = this.solicitud.property.address;
          let payload = this.mandar_cita
        //   this.$log.debug(this.mandar_cita)
          try{
            let response = (await api_formalizacion.creacion_cita(this.solicitud.external_id, payload)).data;
            this.$log.info(response)
            this.$helper.showMessage('Cita realizada exitosamente');

            // Refresca solicitud
			this.obtener_solicitud();
            this.showCitaModal = false
          }catch(err){
            this.$helper.showAxiosError(err, 'Error');
            this.showCitaModal = false
          }
      }
      ,filtrar_valuadores_zonificados: function(event) {
          this.valuadores_zonificados = []
          this.valuadores.forEach(valuador => {
              if (valuador.zone.name == event.target.value)
                this.valuadores_zonificados.push(valuador); 
          });

          if (this.valuadores_zonificados.length < 1)
            this.show_valuador_zonificado = false
          this.$log.info(this.valuadores_zonificados);
      }
      ,asignar_valuador_visita_ocular: async function(e) {
          e.preventDefault();
          let valuador = this.$refs["valuador_zonificado"];
          let payload = {
              appraiser_visit_id: valuador.value
          }
          try{
            let response = (await api.update_solicitud(this.solicitud.id, payload)).data;
            this.$helper.showMessage('Asignación realizada satisfactoriamente');

            // Refresca solicitud
			this.obtener_solicitud();
          }catch(err){
            this.$helper.showAxiosError(err, 'Error');
          }
      }
      ,descarga_documento: async function(e) {
        e.preventDefault();
        // Documento seleccionado en el combo de documentos
        let documento = this.selected_documento
        try {
            const response = await api_formalizacion.descarga_documento(
                                        this.$auth.getAuth(),
                                        documento.nombre
                                        ,documento.nombre_real
                                        ,this.solicitud.external_id
                                        ,documento.documento_id
                                        ,documento.valija_id
                                        ,documento.id);
            if (response.success){
                this.$log.info(response.msg)
                this.$helper.showMessage(response.msg);
            } else {
                const customError = {
                    response:{
                        data: {
                            message: response.msg
                    }}}
                this.$helper.showAxiosError(customError, 'Error')
            }
        } catch(err){
            this.$log.error(err);
            this.$helper.showAxiosError(err, 'Error');
        }
      }
      ,show_valuador_zonificado_function: function(event) {
          this.show_valuador_zonificado = event.target.value != "seleccionar";
      }
      ,show_valuador_function: function(event) {
          this.show_valuador = event.target.value != "seleccionar";
      }
      ,show_documento_fun: function(event) {
          this.selected_documento =  this.documentos[event.target.selectedIndex-1]
          this.show_documento = event.target.value != "seleccionar";
      }
      ,get_email: async function(){
            let contacto = this.$auth.getUser().contactos.find(e => e.tipo == "email");
            if(contacto){
                return contacto.valor;
            }
      }

  },
};
</script>

<style lang="scss" scoped>
table {
	thead {
		background-color: #0781cd;

		th:first-child {
			text-align: left;
		}

		th.ordeable {
			cursor: pointer;
		}

		th.activo {
			color: #0781cd;
		}
	}
	button {
        text-align: center;
        width: 100px;
	}
}
.icon-embed {
    font-size: 0.8em;
    transform: rotate(-90deg);
    display: inline-block;
}
.icon-embed:before {
    content: "\ea7f";
}

.table-ov {
    overflow: auto;
}

.p-2 {
    button {
        text-align: center;
    }
}

.travel-map {
  height: 400px;
}

img {
    width: 100% !important;
    
}
img.property-img{
    max-width: 100% !important;
}
</style>