import axios from '@/plugins/axios'
import config from '@/apps/avaluodigital/config'

const baseURL = process.env.VUE_APP_API_FORMALIZACION;
const baseURLSolicitudes = baseURL + "/solicitudes/";
const baseURLDocumentos = baseURL + "/avaluodigital/solicitud/";

class FormalizacionAPI {

    consulta_documentos(payload, id){
        const url = config.get_documents_url + `/${id}`;
        return new Promise(function(resolve, rej) { 
                resolve(axios.post(url, payload));
            })
            .then(function(result) {
                return { success: true, data: result.data.payload }
            })
            .catch(function(err) {
                return { success: false, error: err }
            })
    }

    consulta_documento(id, valija_id, doc_id){
        let payload = {
            valija_id: valija_id,
            id: doc_id
        }
        axios.get(baseURLDocumentos + id + '/documento', payload)
    }

    concatUrl(map){
        let item_list = []
        for (let [key, value] of map.entries()) {
            if (value){
                item_list.push(`${key}=${value}&`)
            }
        }
        return encodeURI(item_list.join(''))
        
    }

    descarga_documento(payload, nombre, nombre_real,
            solicitud_id, documento_id, valija_id, id){
        
        let url = `${config.descarga_doc_url}/${solicitud_id}?`
        
        let map = new Map()
        map.set('id',  id)
        map.set('documento_id',  documento_id)
        map.set('valija_id',  valija_id)
        map.set('nombre',  nombre)
        map.set('nombre_real',  nombre_real)
        
        url = url + this.concatUrl(map)
        url = url.substring(0, url.length - 1)
        
        console.log('URL:', url)

        const headers = {
            token: payload.token,
            refresh: payload.refresh || payload.refreshToken
        }
        
        const fileName = nombre_real ? nombre_real : nombre
        
        return new Promise(function(resolve, rej) { 
                resolve(axios({
                    method: 'GET',
                    url: url, 
                    responseType: 'blob',
                    headers: headers
                }));
            })
            .then(function(result) {
                let urlFile = window.URL.createObjectURL(new Blob([result.data]));
                let link = document.createElement('a');
                link.href = urlFile;
                link.setAttribute('download',fileName);
                document.body.appendChild(link);
                link.click();

                return { success: true, msg: 'El documento fue descargado exitosamente'}
            })
            .catch(function(err) {
                console.error("Error:", err)
                return { success: false, msg: 'El archivo no fue encontrado'}
            })
    }

    creacion_cita(id, payload) {
        return axios.post(baseURLSolicitudes + id + "/cita", payload)
    }
}

export default new FormalizacionAPI();